<template>
  <div>
    <select-shop id="productCategorySelectShop" :loadingButton="loadingButton" v-on:getData="getProductCategory"></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <CAlert id="productCategorySaveAlert" color="success" v-if="isSuccess === true" :show="Number(3)">
            {{ $t('savedCategory') }}
          </CAlert>
          <div class="row">
            <div class="col-md-10 col-sm-9 col-7">
              <h2 class="font-weight-normal">
                {{ $t('management.categorytManagement') }}
              </h2>
            </div>
            <div class="col-md-2 col-sm-3 col-5 text-right text-success">
              <CButton id="productCategoryAddButton" block color="success"  style="display: flex; align-items: center; justify-content: center;"  @click="addModal()" v-if="isEditData">
                <i class="fi fi-rr-plus" style="margin-right: 4px;"></i>
                {{ $t('addCategory') }}
              </CButton>
            </div>
          </div>
          <hr />
          <CRow>
            <CCol sm="12" lg="12">
              <CDataTable id="productCategoryTable" :items="items" :fields="fields" hover sorter border v-model="items">
                <template #name="{ item,index }">
                  <td :id="'productCategoryName'+ index" class="py-2 text-dark font-weight-normal ">
                    {{ item.name }}  <img src="/img/delivery/grab.png"  v-if="item.source === 'GRAB'" class="rounded-circle"
                    style="border-radius: 3px; width: 20px;" />
                  </td>
                </template>
                <template #color="{ item }">
                  <td class="py-2 text-center">
                    <CButton id="productCategoryColorButton" :style="{ backgroundColor: item.color }" @click="colorSelect = true">
                      <CIcon>
                        <i class="fas fa-square"></i>
                      </CIcon>
                    </CButton>
                  </td>
                </template>

                <template #icon="{ item }">
                 
                  <td class="py-2 text-center">
                    <i :class="item.iconindex" style="font-size: larger;"></i>
                  </td>
                </template>

                <template #enabled="{ item, index }">
                  <td class="py-2 text-center" v-if="isEditData">
                    <CSwitch :id="'productCategorySwitchEnabled'+ index" v-model="item.enabled" color="success" :checked.sync="item.enabled"
                      @update:checked="updateIsEnabled($event, item)" />
                  </td>
                  <td class="py-2 text-center" v-else>
                    <CSwitch :id="'productCategorySwitchDisabledEnabled'+ index" v-model="item.enabled" color="success" :checked.sync="item.enabled" disabled />
                  </td>
                </template>

                <template #isMenu="{ item, index }">
                  <td class="py-2 text-center" v-if="isEditData">
                    <CSwitch :id="'productCategorySwitchIsmenu'+ index" v-model="item.isMenu" color="success" :checked.sync="item.isMenu"
                      @update:checked="updateIsMenu($event, item)" />
                  </td>
                  <td class="py-2 text-center" v-else>
                    <CSwitch :id="'productCategorySwitchIsmenuDisabled'+ index" v-model="item.isMenu" color="success" :checked.sync="item.isMenu" disabled />
                  </td>
                </template>

                <template #action="{ item, index }">
                  <td class="text-center" v-if="isEditData" >
                    <CRow>
                      <CCol v-if="item.id !== '0'">
                      <img :id="'productCategoryeditModal'+ index" @click="editModal(item)" src="/img/file-edit.png" style="width: 14px; height: 14px; cursor: pointer;"/>
                      </CCol>
                      <CCol v-if="item.id !== '0' && isDeleteData">
                      <img :id="'productCategoryDeleteModal'+ index" @click="confirmDelete(item.objectId)" src="/img/trash.png" style="width: 14px; height: 14px;cursor: pointer; "/>
                      </CCol>
                    </CRow>
                  </td>
                </template>

                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </CCol>
          </CRow>

          <pagination :meta_data="meta_data" v-on:next="getProductCategory">
          </pagination>
        </CCardBody>
      </CCard>
      <CModal id="productCategoryAddModal" color :show.sync="addCatagoryModal" :footer="footer" :title="namemodal" centered>
        <CRow>
          <CCol md="12" sm="12">
            <CAlert id="productCategorySaveErrorAlert" color="danger" v-if="saveErrorMsg" :show="Number(1)">
              {{ saveErrorMsg }}
            </CAlert>
            <CInput id="productCategoryNameInput" v-if="validateCategoryname" v-model="categoryname" :placeholder="$t('validateCategoryname')"
              :is-valid="validator" :invalid-feedback="$t('validateCategoryname')" />
            <CInput id="productCategoryNameInputFallback" v-else v-model="categoryname" :placeholder="$t('validateCategoryname')" />
          </CCol>
          <CCol md="2" sm="2" col="6" class="form-group">
            <CRow>
              <CCol md="12" sm="12">
                <CButton id="productCategoryColorButton" :style="{ backgroundColor: colori, width: '36px' }" @click="showcolor = !showcolor">
                  <CIcon>
                    <i class="fas fa-square"></i>
                  </CIcon>
                </CButton>
              </CCol>
              <c-card id="productCategoryColorPickerCard" v-if="showcolor == true" @click="showcolor = !showcolor" class="mt-5 shadow-lg bg-white rounded"
                style="position: absolute;border-radius: 5px;background-color: white;width: 270px;z-index: 100;">
                <CCardBody>
                  <p>{{ $t('selectbgColor') }}</p>
                  <hr />
                  <CRow class="justify-content-center">
                    <CCol xs="6" style="margin: 3px" v-for="(item, index) in colorList" :key="item.value" :value="item.value">
                      <CButton :id="'productCategoryColorOptionButton' + index" :style="{ width: '36px', height: '36px', backgroundColor: item.color }"
                        @click="setIndexColor(item.value)">
                        <CIcon>
                          <i class="fas fa-square"></i>
                        </CIcon>
                      </CButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </c-card>
            </CRow>
          </CCol>
          <CCol md="4" sm="4" col="6" class="col-form-label text-left ">
            {{ $t('bgColor') }}
          </CCol>

          <!-- Mapping Category Icon -->
          <CCol md="2" sm="2" col="6" class="col-form-label text-left " style=" padding-top: 0px;">
            <i @click="showicon = !showicon" :class="selectedicon" style="font-size:36px;"></i>
            <c-card v-if="showicon == true" @click="showicon = !showicon" class="shadow-lg bg-white rounded"
              style="position: absolute;border-radius: 5px;background-color: white;width: 270px;z-index: 100;">
              <CCardBody>
                <p>{{ $t('selectCategoryIcon') }}</p>
                <hr />
                <CRow class="justify-content-center">
                  <CCol xs="6" style="margin: 3px" v-for="(item, index) in icon" :key="item.value" :value="item.value">
                    <CButton :id="'productCategoryIconOptionButton'+ index" :style="{ width: 'auto', height: '80%', padding: '0' }" @click="setIconClass(item)">
                      <i :class="item" style="font-size:36px;"></i>
                    </CButton>
                  </CCol>
                  <CButton @click="selectedicon = 'fi-rr-square-dashed'; iconindex = ''" class="text-danger mt-3"
                    style="text-decoration: underline;">{{$t('clear') }}</CButton>
                </CRow>
              </CCardBody>
            </c-card>
          </CCol>
          <CCol md="4" sm="4" col="6" class="col-form-label text-left ">
            {{ $t('customCategoryIcon') }}
          </CCol>
          <CCol md="2" sm="2" col="6" class="col-form-label text-left ">
            <CSwitch id="productCategoryStatusSwitch" v-model="isEnabled" color="success" :value="isEnabled" :checked.sync="isEnabled" />
          </CCol>
          <CCol v-if="isEnabled == true" md="4" sm="4" col="6" class="col-form-label text-left ">
            {{ $t('usabilityStatus') }}
          </CCol>
          <CCol v-else-if="isEnabled == false" md="4" sm="4" col="6" class="col-form-label text-left ">
            {{ $t('usabilityStatus') }}
          </CCol>
          <!-- isMenuEnabled -->
          <CCol v-if="isMenuEnabled != ''" md="2" sm="2" col="6" class="col-form-label text-left ">
            <CSwitch id="productCategoryIsMenuSwitch" v-model="isMenu" color="success" :value="isMenu" :checked.sync="isMenu" />
          </CCol>
          <CCol v-if="isMenu == true && isMenuEnabled != ''" md="4" sm="4" col="6" class="col-form-label text-left "
            style="white-space: nowrap;">
            {{ $t('isMenu') }}
          </CCol>
          <CCol v-else-if="isMenu == false && isMenuEnabled != ''" md="4" sm="4" col="6"
            class="col-form-label text-left " style="white-space: nowrap;">
            {{ $t('isMenu') }}
          </CCol>
        </CRow>
        <template #footer>
          <CRow class="justify-content-around col-md-12">
            <CCol col="4">
              <CButton id="productCategorySaveButton" v-if="saveLoadingButton === false" color="success" @click="handlePopup" block>
                {{ $t('save') }}
              </CButton>
              <CButton id="productCategorySaveLoadingButton" v-else-if="saveLoadingButton === true" color="success" disabled block>
                <CSpinner color="white" size="sm" />
              </CButton>
            </CCol>
            <CCol col="4">
              <CButton id="productCategoryCancelButton" color="light" v-if="saveLoadingButton === false" @click="addCatagoryModal = false" block>
                {{ $t('cancel') }}
              </CButton>
              <CButton id="productCategoryCancelLoadingButton" v-else color="light" disabled block>
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>

      <CRow>
        <CModal id="productCategorySaveModal" color="success" :title="$t('confirmSaveCategory')" :show.sync="popupModal" centered>
          <br />
          <h4 class="text-center">{{ $t('confirmSaveCategoryDialog') }}</h4>
          <br />

          <template #footer>
            <CRow class="justify-content-around col-md-12">
              <CCol col="4">
                <CButton id="productCategorySaveButton" block color="success" v-if="loadingButton === true" v-on:click="savedata">
                  {{ $t('save') }}
                </CButton>
                <CButton id="productCategorySaveDisabledButton" block color="success" v-else-if="loadingButton === false" v-on:click="savedata" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </CCol>
              <CCol col="4">
                <CButton id="productCategoryCancelSaveButton" color="light" block @click="popupModal = false">
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
      <CRow>
        <CModal id="productCategoryDeleteModal" color="danger" :title="$t('confirmDeleteCategory')" :show.sync="deleteModal" centered>
          <br />
          <h4 class="text-center">{{ $t('dialigDeleteCategory') }}</h4>
          <div class="row justify-content-center">
            <!-- <div class="col-3 text-center" v-for="item in itemlist" :key="item.value" :value="item.value">
              <p>{{ item }}</p>
            </div> -->
          </div>

          <template #footer>
            <CRow class="justify-content-around col-md-12">
              <CCol col="4">
                <CButton id="productCategoryDeleteButton" color="danger" block @click="deleteCategory()" v-if="saveLoadingButton === false">
                  {{ $t('delete') }}
                </CButton>
                <CButton id="productCategoryDeleteDisabledButton" v-else color="danger" block disabled>
                  <CSpinner color="white" size="sm" />
                  {{ $t('delete') }}
                </CButton>
              </CCol>
              <CCol col="4">
                <CButton id="productCategoryCancelDeleteButton" v-if="saveLoadingButton === false" color="light" block @click="deleteModal = false">
                  {{ $t('cancel') }}
                </CButton>
                <CButton id="productCategoryCancelDeleteDisabledButton" v-else color="light" disabled block>
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>

      <!-- Edit Category Modal -->
      <CModal id="productCategoryEditModal" color :show.sync="editCatagoryModal" :footer="footer" :title="namemodal" centered>
        <CRow>
          <CCol md="12" sm="12">
            <CAlert id="productCategorySaveErrorAlert" color="danger" v-if="saveErrorMsg" :show="Number(1)">
              {{ saveErrorMsg }}
            </CAlert>
            <CInput id="productCategoryInputName" v-if="validateCategoryname" v-model="categoryname" :placeholder="$t('validateCategoryname')"
              :is-valid="validator" :invalid-feedback="$t('validateCategoryname')" />
            <CInput id="productCategoryInputNameFallback" v-else v-model="categoryname" :placeholder="$t('validateCategoryname')" />
          </CCol>
          <CCol md="2" sm="2" col="6" class="form-group">
            <CRow>
              <CCol md="12" sm="12">
                <CButton id="productCategoryColorButton" :style="{ backgroundColor: colori }" @click="showcolor = !showcolor">
                  <CIcon>
                    <i class="fas fa-square"></i>
                  </CIcon>
                </CButton>
              </CCol>
              <c-card v-if="showcolor == true" @click="showcolor = !showcolor" class="shadow-lg mb-5 bg-white rounded"
                style="position: absolute;border-radius: 5px;background-color: white;width: 270px;z-index: 100;">
                <CCardBody>
                  <p>{{ $t('selectbgColor') }}</p>
                  <hr />
                  <CRow>
                    <CCol md="2" sm="2" style="margin: 3px" v-for="item in colorList" :key="item.value"
                      :value="item.value">
                      <CButton id="productCategoryColorOption" :style="{ backgroundColor: item.color }" @click="setIndexColor(item.value)">
                        <CIcon>
                          <i class="fas fa-square"></i>
                        </CIcon>
                      </CButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </c-card>

            </CRow>
          </CCol>
          <CCol md="4" sm="4" col="6" class="col-form-label text-left ">
            {{ $t('bgColor') }}
          </CCol>

          <CCol md="2" sm="2" col="6" class="col-form-label text-left " style=" padding-top: 0px;">
            <i @click="showicon = !showicon" :class="selectedicon" style="font-size:36px;"></i>
            <c-card v-if="showicon == true" @click="showicon = !showicon" class="shadow-lg bg-white rounded"
              style="position: absolute;border-radius: 5px;background-color: white;width: 270px;z-index: 100;">
              <CCardBody>
                <p>{{ $t('selectCategoryIcon') }}</p>
                <hr />
                <CRow class="justify-content-center">
                  <CCol xs="6" style="margin: 3px" v-for="item in icon" :key="item.value" :value="item.value">
                    <CButton id="productCategoryIconClearButton" :style="{ width: 'auto', height: '80%', padding: '0' }" @click="setIconClass(item)">
                      <i :class="item" style="font-size:36px;"></i>
                    </CButton>
                  </CCol>
                  <CButton @click="selectedicon = 'fi-rr-square-dashed'; iconindex = ''" class="text-danger mt-3"
                    style="text-decoration: underline;">{{$t('clear') }}</CButton>
                </CRow>

              </CCardBody>
            </c-card>
          </CCol>
          <CCol md="4" sm="4" col="6" class="col-form-label text-left ">
            {{ $t('customCategoryIcon') }}
          </CCol>


          <CCol md="2" sm="2" col="6" class="col-form-label text-left ">
            <CSwitch id="productCategorySwitchEnabled" v-model="isEnabled" color="success" :value="isEnabled" :checked.sync="isEnabled" />
          </CCol>
          <CCol v-if="isEnabled == true" md="4" sm="4" col="6" class="col-form-label text-left ">
            {{ $t('usabilityStatus') }}
          </CCol>
          <CCol v-else-if="isEnabled == false" md="4" sm="4" col="6" class="col-form-label text-left ">
            {{ $t('usabilityStatus') }}
          </CCol>

          <CCol v-if="isMenuEnabled != ''" md="2" sm="2" col="6" class="col-form-label text-left ">
            <CSwitch id="productCategorySwitchMenu" v-model="isMenu" color="success" :value="isMenu" :checked.sync="isMenu" />
          </CCol>
          <CCol v-if="isMenu == true && isMenuEnabled != ''" md="4" sm="4" col="6" class="col-form-label text-left ">
            {{ $t('isMenu') }}
          </CCol>
          <CCol v-else-if="isMenu == false && isMenuEnabled != ''" md="4" sm="4" col="6"
            class="col-form-label text-left ">
            {{ $t('isMenu') }}
          </CCol>
        </CRow>



        <template #footer>
          <CRow class="justify-content-around col-md-12">
            <CCol col="4">
              <CButton id="productCategorySaveButton" v-if="saveLoadingButton === false" color="success" @click="handlePopup" block>
                {{ $t('save') }}
              </CButton>
              <CButton id="productCategorySaveDisabledButton" v-else-if="saveLoadingButton === true" color="success" disabled block>
                <CSpinner color="white" size="sm" />
              </CButton>
            </CCol>
            <CCol col="4">
              <CButton id="productCategoryCancelButton" color="light" v-if="saveLoadingButton === false" @click="editCatagoryModal = false" block>
                {{ $t('cancel') }}
              </CButton>
              <CButton id="productCategoryCancelDisabledButton" v-else color="light" disabled block>
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import local from '@/services/local'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import permis from '@/util/permission'
import categoryIcon from '@/assets/icons/categoryicon'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      namemodal: '',
      isSuccess: false,
      data: [],
      popupModal: false,
      deleteModal: false,
      validateCategoryname: '',
      addCatagoryModal: false,
      isEnabled: true,
      loadingButton: true,
      categoryname: '',
      colorindex: '',
      colori: '',
      iconindex: '',
      iconi: '',
      countlist: '',
      showcolor: false,
      showicon: false,
      selectedicon: 'fi-rr-square-dashed',
      searchLoadingButton: true,
      keyword: '',
      itemId: '',
      itemObjectId: '',
      shop: this.$store.getters.shopObjectId,
      footer: '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      currentSortDir: 'desc',
      currentSort: '',
      sequence: '',
      itemDelete: {},
      all: false,
      itemlist: [],
      isEdit: false,
      colorSelect: false,
      isMenu: false,
      deleteLists: [],
      saveLoadingButton: false,
      editCatagoryModal: false,
      icon: [],
      saveErrorMsg: '',
      i: '',
      isEcommerce:false
    }
  },
  created() {
    this.getProductCategory()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
    this.icon = categoryIcon.getCategoryIcon()
    this.handleEcommerce()
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      return permis.findPermissionRead('product', this.$route.path)
    },
    isEditData() {
      return permis.findPermissionEdit('product', this.$route.path)
    },
    isDeleteData() {
      return permis.findPermissionRemove('product', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    itempage() {
      return this.meta_data.current_page * 50 - 50
    },
    fields() {
      if (this.isMenuEnabled !== '') {
        if (this.isEditData) {
          return [
            {
              key: 'num',
              label: '#',
              sorter: false,
              _style: 'width:5%',
              _classes: 'text-dark font-weight-normal'
            },
            {
              key: 'name',
              label: this.$i18n.t('category'),
              sorter: false,
              _style: 'width:45%',
              _classes: 'text-dark font-weight-normal'
            },
            {
              key: 'color',
              label: this.$i18n.t('bgColor'),
              _classes: 'text-center text-dark font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'icon',
              label: this.$i18n.t('icon'),
              _classes: 'text-center text-dark font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'enabled',
              label: this.$i18n.t('active'),
              _classes: 'text-center text-dark font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'isMenu',
              label: this.$i18n.t('isMenu'),
              _classes: 'text-center text-dark font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'action',
              label: '',
              _classes: 'text-center text-dark font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
          ]
        } else {
          return [
            {
              key: 'num',
              label: '#',
              sorter: false,
              _style: 'width:5%',
              _classes: 'text-dark font-weight-normal'
            },
            {
              key: 'name',
              label: this.$i18n.t('category'),
              sorter: false,
              _style: 'width:45%',
              _classes: 'text-dark font-weight-normal'
            },
            {
              key: 'color',
              label: this.$i18n.t('bgColor'),
              _classes: 'text-center font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'icon',
              label: this.$i18n.t('icon'),
              _classes: 'text-center text-dark font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'enabled',
              label: this.$i18n.t('active'),
              _classes: 'text-center font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'isMenu',
              label: this.$i18n.t('isMenu'),
              _classes: 'text-center font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
          ]
        }
      } else {
        if (this.isEditData && !this.isEcommerce) {
          return [
            {
              key: 'num',
              label: '#',
              sorter: false,
              _style: 'width:5%',
              _classes: 'font-weight-normal',
            },
            {
              key: 'name',
              label: this.$i18n.t('category'),
              sorter: false,
              _style: 'width:55%',
              _classes: 'font-weight-normal',
            },
            {
              key: 'color',
              label: this.$i18n.t('bgColor'),
              _classes: 'text-center font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'icon',
              label: this.$i18n.t('icon'),
              _classes: 'text-center text-dark font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'enabled',
              label: this.$i18n.t('active'),
              _classes: 'text-center font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'action',
              label: '',
              _classes: 'text-center font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
          ]
        } else if (this.isEcommerce) {
          return [
            {
              key: 'num',
              label: '#',
              sorter: false,
              _style: 'width:5%',
              _classes: 'font-weight-normal',
            },
            {
              key: 'name',
              label: this.$i18n.t('category'),
              sorter: false,
              _style: 'width:30%',
              _classes: 'font-weight-normal',
            },
            {
              key: 'color',
              label: this.$i18n.t('bgColor'),
              _classes: 'text-center font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'icon',
              label: this.$i18n.t('icon'),
              _classes: 'text-center text-dark font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'enabled',
              label: this.$i18n.t('active'),
              _classes: 'text-center font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
          ]
        } else {
          return [
            {
              key: 'num',
              label: '#',
              sorter: false,
              _style: 'width:5%',
              _classes: 'font-weight-normal',
            },
            {
              key: 'name',
              label: this.$i18n.t('category'),
              sorter: false,
              _style: 'width:45%',
              _classes: 'font-weight-normal',
            },
            {
              key: 'color',
              label: this.$i18n.t('bgColor'),
              _classes: 'text-center font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'icon',
              label: this.$i18n.t('icon'),
              _classes: 'text-center text-dark font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
            {
              key: 'enabled',
              label: this.$i18n.t('active'),
              _classes: 'text-center font-weight-normal',
              sorter: false,
              _style: 'width:10%',
            },
          ]
        }
      }
    },
    items() {
      let data = this.data.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      let detail = []
      let start = this.meta_data.current_page * 50 - 50
      for (let i = 0; i < data.length; i++) {
        let isMenu = false
        const  source = data[i].source ? data[i].source : ''
        if (data[i].isMenu !== undefined) {
          isMenu = data[i].isMenu
        } else {
          // เพิ่ม object isMenu ถ้าไม่มี
          data[i].isMenu = false
        }
        detail.push({
          num: start + i + 1,
          name: data[i].name,
          id: data[i].id,
          color: util.generateColor(data[i].indexColor),
          colorindex: data[i].indexColor,
          iconindex: data[i].indexIcon,
          enabled: data[i].enabled,
          sequence: data[i].sequence,
          objectId: data[i].objectId,
          selected: false,
          disabled: false,
          showcolor: false,
          isMenu: data[i].isMenu,
          source: source
        })
      }
      return detail
    },
    colorList() {
      let data = []
      for (let i = 0; i < 30; i++) {
        data.push({
          color: util.generateColor(i),
          value: i,
        })
      }
      return data
    },
    isMenuEnabled() {
      if (this.shop) {
        if (this.shop.isMenuEnabled !== undefined) {
          return this.shop.isMenuEnabled
        } else {
          return ''
        }
      }
    },
  },
  methods: {
    handleEcommerce() {
      if (this.shop && this.shop.shopee && this.shop.shopee.product !== undefined) {
        if (this.shop.shopee.product === true) {
          this.isEcommerce = true
        } else {
          this.isEcommerce = false
        }
      }else{
        this.isEcommerce = false
      }
    },
    handlePopup() {
      if (this.categoryname == '') {
        this.validateCategoryname = this.$i18n.t('validateCategoryname')
      } else {
        this.validateCategoryname = ''
        this.savedata()
      }
    },
    sorting(key) {
      this.currentSort = key
      this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      this.isActive = !this.isActive
    },
    addModal() {
      this.itemId = ''
      this.itemObjectId = ''
      this.isEnabled = true
      this.categoryname = ''
      this.isMenu = false
      this.colorindex = 1
      this.iconindex = ''
      this.selectedicon = 'fi fi-rr-square-dashed'
      this.colori = util.generateColor(this.colorindex)
      this.namemodal = this.$i18n.t('addCategory')
      this.addCatagoryModal = true
    },
    editModal(item) {
      this.namemodal = this.$i18n.t('editCategory')
      this.editCatagoryModal = true
      this.itemId = item.id
      this.itemObjectId = item.objectId
      this.isEnabled = item.enabled
      this.colori = util.generateColor(item.colorindex)
      this.colorindex = item.colorindex
      if (item.iconindex == undefined || item.iconindex == '') {
        this.selectedicon = 'fi fi-rr-square-dashed'
      } else {
        this.selectedicon = item.iconindex
      }
      this.iconindex = item.iconindex
      this.categoryname = item.name
      this.sequence = item.sequence
      this.validateCategoryname = ''
      this.isMenu = item.isMenu
    },
    confirmDelete(objectId) {
      this.deleteLists = []
      let list = this.items
      let data = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].objectId === objectId && list[i].id !== '0') {
          data.push(list[i].name)
          this.deleteLists.push(list[i].objectId)
        }
      }
      this.itemlist = data
      this.deleteModal = true
    },
    deleteCategory() {
      this.saveLoadingButton = true
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const shopObjectId = this.shopObjectId
      let data = {
        shopObjectId: shopObjectId,
        list: this.deleteLists,
      }
      const headers = { shopObjectId: shopObjectId }

      local({
        method: 'post',
        //url: '/api/v1.0/' + uid + '/Category/softdelete', //delete 1 items
        url: '/api/v1.0/' + uid + '/Category/softdeletemany', //delete items many
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.deleteModal = false
            this.addCatagoryModal = false
            this.getProductCategory()
            this.saveLoadingButton = false
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    setEditIndexColor(color, index) {
      this.colorindex = color
      this.items[index].colorindex = color
      this.items[index].color = util.generateColor(color)
    },
    setIndexColor(color) {
      this.colorindex = color
      this.colori = util.generateColor(color)
    },
    isSelectedIcon() {
      if (selectedicon == '') {
        selectedicon = 'fi fi-rr-square-dashed'
        return selectedicon
      } else {
        return selectedicon
      }
    },
    setIconClass(iconclass) {
      this.selectedicon = iconclass
      this.iconindex = iconclass
    },

    getProductCategory(page = 1) {
      this.searchLoadingButton = false
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const params = { page: page, limit: 50 }
      const headers = { shopObjectId: shopObjectId }
      local({
        url: '/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId,
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.data = res.data.data
        if (res.data.paginate.items == undefined) {
          this.meta_data.items = res.data.paginate.itemCount
        } else {
          this.meta_data.items = res.data.paginate.items
        }
        this.meta_data.last_page = res.data.paginate.pageCount
        this.meta_data.current_page = res.data.paginate.currentPage
        this.meta_data.itemCount = res.data.paginate.itemCount
        this.meta_data.limit = res.data.paginate.perPage
        this.countlist = res.data.length
        this.searchLoadingButton = true
        this.loadingButton = true
      })
    },
    savedata() {
      this.saveLoadingButton = true
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan
      let data = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].isMenu !== undefined) {
          isMenu = data[i].isMenu
        } else {
          // เพิ่ม object isMenu ถ้าไม่มี
          data[i].isMenu = true
        }
      }
      if (this.itemObjectId == '') {
        data = {
          enabled: this.isEnabled, //ใช้งาน
          indexColor: this.colorindex,
          indexIcon: this.iconindex,
          name: this.categoryname,
          sequence: this.data.length + 1, // ลำดับแสดงผล
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          uid: uid,
          isMenu: this.isMenu
        }
      } else {
        data = {
          id: this.itemId, //item id
          objectId: this.itemObjectId, // objectid item
          enabled: this.isEnabled, //ใช้งาน
          indexColor: this.colorindex,
          indexIcon: this.iconindex,
          name: this.categoryname,
          sequence: this.sequence, // ลำดับแสดงผล
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          uid: uid,
          isMenu: this.isMenu
        }
      }

      if (!('isMenu' in data)) {
        Object.assign(data, { isMenu: this.isMenu })
      }

      const headers = { shopObjectId: shopObjectId }

      local({
        method: 'post',
        url: '/api/v1.0/manage/' + uid + '/Category/create',
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.isSuccess = true
            this.categoryname = ''
            this.colorindex = ''
            this.iconindex = ''
            this.isEnabled = true
            this.saveLoadingButton = false
            this.popupModal = false
            this.addCatagoryModal = false
            this.editCatagoryModal = false
            this.getProductCategory()
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    updateIsEnabled(e, item) {
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan
      const headers = { shopObjectId: shopObjectId }
      let data = {
        id: item.id, //item id
        objectId: item.objectId, // objectid item
        enabled: e, //ใช้งาน
        shop: {
          objectId: this.shop.objectId,
          id: this.shop.id,
          branchName: this.shop.branchName,
        },
        shopId: this.shop.id,
        uid: uid,
        isMenu: this.isMenu
      }

      local({
        method: 'post',
        url: '/api/v1.0/manage/' + uid + '/Category/create',
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.isSuccess = true
            this.getProductCategory()
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    updateIsMenu(e, item) {
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan
      const headers = { shopObjectId: shopObjectId }

      // สร้าง object data ขึ้นมา
      let data = {
        id: item.id,
        objectId: item.objectId,
        shop: {
          objectId: this.shop.objectId,
          id: this.shop.id,
          branchName: this.shop.branchName,
        },
        shopId: this.shop.id,
        uid: uid,
        isMenu: e
      }

      // ตรวจสอบว่า data มีคุณสมบัติ 'isMenu' หรือไม่
      if (!('isMenu' in data)) {
        // ถ้าไม่มีให้เพิ่ม object 'isMenu' เข้าไป
        Object.assign(data, { isMenu: e })
      }

      // ทำการส่งข้อมูลไปยัง Backend API
      local({
        method: 'post',
        url: '/api/v1.0/manage/' + uid + '/Category/create',
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
        headers: headers,
      })
        .then(() => {
          // กระทำหลังจากส่งข้อมูลสำเร็จ
          this.isSuccess = true
          this.getProductCategory()
        })
        .catch((error) => {
          console.log(error)
        })
    },

  },
}
</script>

<style>
.form-control {
  cursor: pointer;
}

.custom-select {
  cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: default;
}

.cursor {
  cursor: pointer;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #29b289;
  background-color: #29b289;
}
</style>