const CategoryIcon = [
    "fi fi-rr-fish",
    "fi fi-rr-shrimp",
    "fi fi-rr-bacon",
    "fi fi-rr-drumstick",
    "fi fi-rr-egg",
    "fi fi-rr-pizza-slice",
    "fi fi-rr-noodles",
    "fi fi-rr-bowl-rice",
    "fi fi-rr-acorn",
    "fi fi-rr-apple-whole",
    "fi fi-rr-cherry",
    "fi fi-rr-lettuce",
    "fi fi-rr-leaf",
    "fi fi-rr-wheat",
    "fi fi-rr-candy-alt",
    "fi fi-rr-bread-slice",
    "fi fi-rr-croissant",
    "fi fi-rr-coffee-beans",
    "fi fi-rr-mortar-pestle",
    "fi fi-rr-drink-alt",
    "fi fi-rr-mug-hot",
    "fi fi-rr-glass",
    "fi fi-rr-cocktail-alt",
    "fi fi-rr-salt-pepper",
    "fi fi-rr-restaurant",
  ];
  
  export default {
    getCategoryIcon(){
        return CategoryIcon
    }
}
  
  